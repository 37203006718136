// AboutPageTemplate/index.js
//
// React Component for main content of About Us page
// URLs: /about/

import React from 'react'
import Content from '../Content'
import ProfileCards from '../ProfileCards'
import PropTypes from 'prop-types'
import './index.css'

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
if(typeof window !== 'undefined' || typeof document !== 'undefined'){
  if (window.location.pathname.indexOf('admin') > 0) {
    AOS.init({
      disable: false,
    });

  } else AOS.init({disable: false});
}

function getWindowDimensions() {
  if (typeof windowGlobal === 'undefined') {
    return 600
  } else {
    const { innerWidth: width } = windowGlobal
    return {
      width,
    }
  }
}

const windowGlobal = typeof window !== 'undefined' && window

const AboutPageTemplate = ({ title, subtitle, section1_title,
  section1_subtitle,
  section1_description,
  section1_img,
  section1_alt,
  section2_title,
  section2_subtitle,
  section3_title,
  section3_subtitle,
  section3_description,
  section4_title, offerings }) => {

  const { width } = getWindowDimensions()
  const DetermineWidth = () => {
    return <iframe id='map' src="https://www.google.com/maps/d/u/1/embed?mid=1UIO0_LBQerLEK_3YCpuTDqimcdj7kDE&ehbc=2E312F" scrolling='no' />
  }

  return (
    <div>
      <section id='aboutheader' className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title' data-aos='zoom-out' data-aos-duration='1000'>
                    {title}
                  </h1>
                  <h2 className='subtitle' data-aos='zoom-out' data-aos-duration='1000'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section3 aboutus'>
          <div className='columns'>
            <div className='column is-half about'>
              <div className='column is-10 is-offset-1'>
                <div className="aboutHeaderGroup">
                  <h5 className="mapText" data-aos='zoom-in' data-aos-duration='1000'> {section1_subtitle}</h5>
                  <h5 className="styledHeader" data-aos='zoom-in' data-aos-duration='1000' >{section1_title}</h5> 
                </div>
                <div>
                  <h5 className="aboutText" data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='250'> {section1_description}</h5>
                </div>
              </div>          
            </div>
            <div className='column is-half cause'>
              <img loading='lazy' alt={section1_alt} src={section1_img}></img>
            </div>
          </div>
      </section>
      <section className='section3 partners'>
        <div className='row'>
          <h5 className='styledHeader padtop is-centered'>{section2_title}</h5>
          <h5 className='is-centered padbottom'> {section2_subtitle} </h5>
          <div className='columns logos'>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='0'>
              <a href='https://www.trimsonline.com/'><img loading='lazy' src={require('../../assets/img/Trims.png')} height='100px' width='100px' alt='Trimson Line Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='100'>
              <a href='https://chemistry.vassar.edu/bios/'><img loading='lazy' src={require('../../assets/img/Vassar.png')} height='100px' width='100px' alt='Vassar Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='200'>
              <a href='https://www.redmaplevineyard.com/'><img loading='lazy' src={require('../../assets/img/RMV.png')} height='150px' width='150px' alt='Red Maple Vineyard Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='300'>
              <a href='https://maps.network/'><img loading='lazy' src={require('../../assets/img/MAPS.png')} height='100px' alt='MAPS Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='400'>
              <a href='http://www.sewmaskshv.org/'><img loading='lazy' src={require('../../assets/img/SewMasks.png')} height='100px' width='150px' alt='Sew Masks Hudson Valley Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='500'>
              <a href='http://www.gardinerliquidmercantile.com/'><img loading='lazy' src={require('../../assets/img/Gardiner.png')} height='100px' width='100px' alt='Gardiner Liquid Mercantile Logo' /></a>
            </div>
          </div>
          <div className='columns logos row2'>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='600'>
              <a href='https://www.continental-industry.com/'><img loading='lazy' src={require('../../assets/img/Continental.png')} height='100px' width='200px' alt='Continental Industry Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='700'>
              <a href='https://www.generalplastics.com/'><img loading='lazy' src={require('../../assets/img/GP.png')} height='100px' width='250px' alt='General Plastics Logo' /></a>
            </div>

            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='800'>
              <a href='https://www.cornell.edu/'><img loading='lazy' className='bw' src={require('../../assets/img/cornell.svg')} height='100px' width='250px' alt='Cornell Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='900'>
              <a href='https://www.neighborsforrefugees.org/'><img loading='lazy' className='bw' src={require('../../assets/img/nfr.png')} height='100px' width='250px' alt='Neighbors for Refugees Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1000'>
              <a href='http://activefoam.com/'><img loading='lazy' className='bw' src={require('../../assets/img/activefoam.png')} height='100px' width='150px' alt='Active Foam Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1100'>
              <a id='Polymer' href='http://polymermarketinginc.com/'>Polymer Marketing</a>
            </div>
          </div>
          <div className='columns logos row2'>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1200'>
              <a href='https://www.halcousa.com/'><img loading='lazy' className='bw' src={require('../../assets/img/halco.png')} height='100px' width='250px' alt='Halco Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1300'>
              <a href='https://asa-ten.com/'><img loading='lazy' className='' src={require('../../assets/img/asa.svg')} height='100px' width='100px' alt='Asa Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1400'>
              <a href='https://ithacagenerator.org/'><img loading='lazy' className='bw' src={require('../../assets/img/ithaca.png')} height='100px' width='100px' alt='Ithaca Generator Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1500'>
              <a href='https://www.masksforny.org/'><img loading='lazy' className='bw2' src={require('../../assets/img/masks4ny.png')} height='100px' width='200px' alt='Masks for NY Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1600'>
              <a href='https://suny.oneonta.edu/'><img loading='lazy' className='bw2' src={require('../../assets/img/SUNY.png')} height='100px' width='200px' alt='SUNY Oneonta Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1700'>
              <a href='https://fabscrap.org/'><img loading='lazy' className='bw2' src={require('../../assets/img/fab.png')} height='100px' width='100px' alt='Fab Scrap Logo' /></a>
            </div>
          </div>
          <div className='columns logos3 row2'>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1800'>
              {/* <a href='https://shopvida.com/'><img loading='lazy' className='bw' src={require('../../assets/img/vida.png')} height='100px' width='250px' alt='Vida Logo' /></a> */}
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1800'>
              <a href='https://shopvida.com/'><img loading='lazy' className='bw' src={require('../../assets/img/vida.png')} height='100px' width='250px' alt='Vida Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='1900'>
              <a href='https://hudsonriverhousing.org/'><img loading='lazy' className='bw' src={require('../../assets/img/HudsonRiver.png')} height='100px' width='150px' alt='Hudson River Housing Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='2000'>
              <a href='https://bonafidemasks.com/'><img loading='lazy' className='bw' src={require('../../assets/img/bona_fide_masks.png')} height='150px' width='150px' alt='Ithaca Generator Logo' /></a>
            </div>
            <div className='column is-2' data-aos='flip-left' data-aos-duration='2000' data-aos-delay='2100'>
              <a href='https://weardiop.com/'><img loading='lazy' className='bw' src={require('../../assets/img/diop.png')} height='100px' width='200px' alt='Masks for NY Logo' /></a>
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='columns'>
          <div className='column is-half map'>
            <DetermineWidth />
          </div>
          <div className='column is-half mapdesc'>
            <div className='column is-10 is-offset-1' data-aos='fade-right' data-aos-duration='2000' data-aos-delay='300'>
              <div className='mapHeaderGroup'>
                <h5 className='mapText' data-aos='fade-right' data-aos-duration='2000'> {section3_subtitle} </h5>
                <h5 className='styledHeader'>{section3_title}</h5>
              </div>
              <div className='mapTextGroup'>
                <h5 className='mapText bottom'> {section3_description} </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h5 className='styledHeader is-centered'>{section4_title}</h5>
                <br />
                <ProfileCards gridItems={offerings.blurbs} className='column is-4' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  isCMS: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  section1_title: PropTypes.string,
  section1_subtitle: PropTypes.string,
  section1_description: PropTypes.string,
  section1_img: PropTypes.string,
  section1_alt: PropTypes.string,
  section2_title: PropTypes.string,
  section2_subtitle: PropTypes.string,
  section3_title: PropTypes.string,
  section3_subtitle: PropTypes.string,
  section3_description: PropTypes.string,
  section4_title: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default AboutPageTemplate
