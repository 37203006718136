import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import AboutPageTemplate from '../components/AboutPageTemplate'
import Layout from '../components/Layout'

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <AboutPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        section1_title={post.frontmatter.section1_title}
        section1_subtitle={post.frontmatter.section1_subtitle}
        section1_description={post.frontmatter.section1_description}
        section1_img={post.frontmatter.section1_img}
        section1_alt={post.frontmatter.section1_alt}
        section2_title={post.frontmatter.section2_title}
        section2_subtitle={post.frontmatter.section2_subtitle}
        section3_title={post.frontmatter.section3_title}
        section3_subtitle={post.frontmatter.section3_subtitle}
        section3_description={post.frontmatter.section3_description}
        section4_title={post.frontmatter.section4_title}
        offerings={post.frontmatter.offerings}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        meta_title
        meta_description
        title
        subtitle
        section1_title
        section1_subtitle
        section1_description
        section1_img
        section1_alt
        section2_title
        section2_subtitle
        section3_title
        section3_subtitle
        section3_description
        section4_title
        offerings {
          blurbs {
            image
            text
            title
            subtitle
          }
        }
      }
    }
  }
`
